<script>
import HasError from './HasError.vue'
import HasFormContext from './HasFormContext.vue'
import InheritListeners from './InheritListeners.vue'

export default {
  mixins: [InheritListeners, HasError, HasFormContext],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
}
</script>
