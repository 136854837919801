<script>
import { set } from 'lodash-es'

export default {
  inject: {
    formContext: {
      default: null,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    hasFormContext() {
      if (!this.formContext) {
        return false
      }

      if (!this.formContext.model) {
        return false
      }

      return this.name
    },
    formIsProcessing() {
      if (!this.formContext) {
        return false
      }

      if (!this.formContext.model) {
        return false
      }

      return !!this.formContext.model.processing
    },
  },
  methods: {
    emitValue(event, value) {
      if (this.hasFormContext) {
        set(this.formContext.model, this.name, value)
      } else {
        this.$emit('update:modelValue', value)
      }
    },
    emitInputValue(value) {
      this.emitValue('input', value)
    },
    emitChangeValue(value) {
      this.emitValue('change', value)
    },
  },
}
</script>
