<template>
  <span
    v-if="label"
    class="text-neutral-500 dark:text-neutral-400 text-sm mb-0 inline-block align-middle"
    >{{ label }}</span
  >
</template>

<script>
import { Label } from '@/Components/Shared/Form/Core'

export default Label
</script>
