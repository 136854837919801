<template>
  <form v-bind="$attrs" :method="method">
    <fieldset :disabled="isProcessing">
      <slot />
    </fieldset>
  </form>
</template>

<script>
import { Form } from '@/Components/Shared/Form/Core'

export default Form
</script>
