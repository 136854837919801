<script setup>
import useRoute from '@/Composables/useRoute'
import Logo from '@/Components/Shared/Header/Logo.vue'

const route = useRoute

const props = defineProps({
  noIcon: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <Link :href="route('dashboard')" class="p-3 pl-4 hover:opacity-75">
    <Logo class="pt-0" size="small" :no-icon="props.noIcon" />
  </Link>
</template>
