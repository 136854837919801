<template>
  <div
    v-if="incidentStatus && shouldShowStatus"
    class="z-50 bottom-0 fixed left-2 right-2 flex justify-center"
  >
    <div
      class="rounded-md bg-beige/50 dark:bg-black/70 backdrop-blur-md z-50 font-bold text-sm whitespace-nowrap absolute bottom-2 max-w-full"
    >
      <div
        class="min-w-0 p-3 md:p-2 md:!pl-4 flex flex-col md:flex-row items-center rounded-md"
        :class="{
          'bg-success/50 dark:bg-success/10 border border-success/20 dark:text-success text-black':
            incidentStatus === 'success',
          'bg-warning/50 dark:bg-warning/10 border border-warning/20 dark:text-warning text-black':
            incidentStatus === 'warning',
          'bg-error/50 dark:bg-error/10 border border-error/20 dark:text-error text-black':
            incidentStatus === 'error',
        }"
      >
        <span class="flex items-center mr-2">
          <Icon name="AlertCircle" class="w-4 h-4 mr-3" />
          {{ message }}
        </span>
        <div class="flex items-center w-full md:ml-2 mt-2 md:mt-0">
          <a
            v-if="url"
            class="w-full flex-1 text-xs h-8 px-4 rounded-md bg-white/50 hover:bg-white/70 dark:bg-black/20 dark:hover:bg-black/40 flex items-center"
            :href="url"
            target="_blank"
            >Read more</a
          >
          <button
            class="w-full flex-1 text-xs h-8 px-4 rounded-md bg-white/50 hover:bg-white/70 dark:bg-black/20 dark:hover:bg-black/40 ml-2 flex items-center"
            @click="shouldShowStatus = false"
          >
            <Icon name="X" class="w-5 h-5 mr-2 -ml-2" /> Don't show again
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStorage } from '@vueuse/core'
import { ref } from 'vue'

const incidentStatus = ref(null)
const shouldShowStatus = useStorage('shouldShowStatus', 'slowUploadSpeeds')

const url = null
const message =
  'We are releasing a uploader update, updates are temporarily disabled.'
</script>
