<template>
  <div
    v-bind="$attrs"
    :class="
      twMerge(
        'mt-4',
        disabled ? 'opacity-60 dark:opacity-30 pointer-events-none' : ''
      )
    "
  >
    <Label :label="label" />

    <div
      :class="{
        'mt-2': label,
        'flex flex-wrap': inline,
        'flex flex-col gap-2': !inline,
      }"
    >
      <slot />
    </div>
    <Error v-if="errorMessage" :message="errorMessage" />
  </div>
</template>

<script>
import { Group } from '@/Components/Shared/Form/Core'
import Error from './Error.vue'
import Label from './Label.vue'
import { twMerge } from 'tailwind-merge'

export default {
  components: { Error, Label },
  mixins: [Group],

  setup() {
    return {
      twMerge,
    }
  },
}
</script>
