<template>
  <IncidentStatus />
  <div class="flex min-h-screen">
    <SideMenu :is-builder="isBuilder" />
    <div
      class="flex-1 flex flex-col w-full"
      :class="{ 'lg:pl-[300px]': !isBuilder }"
    >
      <TopBar v-if="currentRoute !== 'stream.builder'" />
      <slot />
    </div>
  </div>
  <Chat v-if="!isAdmin" />
  <Toastify />
</template>

<script setup>
import SideMenu from '@/Components/Shared/Navigation/SideMenu.vue'
import IncidentStatus from '@/Components/Shared/IncidentStatus.vue'
import TopBar from '@/Components/Shared/Navigation/TopBar.vue'
import Toastify from '@/Components/Shared/Elements/Toastify.vue'
import Chat from '@/Components/Shared/Chat.vue'
import { computed, watch, nextTick } from 'vue'
import { usePage } from '@inertiajs/inertia-vue3'
import useToastify from '@/Composables/useToastify'

const currentRoute = computed(() => usePage().props.value.activeRoute)
const isBuilder = computed(() => currentRoute.value.includes('builder'))

const success = computed(() => usePage().props.value.flash.success)
const warning = computed(() => usePage().props.value.flash.warning)
const error = computed(
  () => usePage().props.value.flash.error || usePage().props.value.errors.flash
)
const isAdmin = computed(() => usePage().props.value.auth.is_admin)

watch(
  success,
  (text) => {
    if (text) {
      useToastify({ text }, 'success')
      nextTick(() => {
        usePage().props.value.flash.success = null
      })
    }
  },
  {
    immediate: true,
  }
)

watch(
  warning,
  (text) => {
    if (text) {
      useToastify({ text }, 'warning')
      nextTick(() => {
        usePage().props.value.flash.warning = null
      })
    }
  },
  {
    immediate: true,
  }
)
watch(error, (text) => {
  if (text) {
    useToastify({ text }, 'error')
    nextTick(() => {
      usePage().props.value.flash.error = null
      usePage().props.value.errors.flash = null
    })
  }
})
</script>
