<template>
  <svg
    width="28"
    height="19"
    viewBox="0 0 28 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5524 0C2.39201 0 0 1.67675 0 9.49999C0 17.3232 2.39201 19 13.5524 19C24.7129 19 27.1049 17.3232 27.1049 9.49999C27.1049 1.67675 24.7129 0 13.5524 0ZM23.6502 9.56642C21.0953 5.31401 17.463 2.65733 13.4333 2.65733C9.40347 2.65734 5.77125 5.31401 3.21634 9.56642L3.21631 9.56642C5.77122 13.8188 9.40344 16.4755 13.4332 16.4755C17.463 16.4755 21.0952 13.8188 23.6501 9.56642H23.6502Z"
    />
    <rect
      x="7.97217"
      y="5.84615"
      width="2.25874"
      height="7.44056"
      rx="1.12937"
    />
    <rect
      x="16.4756"
      y="5.84615"
      width="2.25874"
      height="7.44056"
      rx="1.12937"
    />
    <rect
      x="12.2236"
      y="4.91608"
      width="2.25874"
      height="9.30069"
      rx="1.12937"
    />
  </svg>
</template>
