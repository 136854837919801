<template>
  <div class="mt-4">
    <label class="block">
      <Label :label="label" />
      <div :class="{ 'mt-1': label }">
        <a
          class="p-3 form-control w-full cursor-pointer relative block"
          @submit.prevent
        >
          <slot>Browse...</slot>
          <input
            v-bind="$attrs"
            ref="input"
            :name="name"
            :multiple="multiple"
            type="file"
            class="invisible absolute inset-0 w-full h-full disabled:opacity-50"
            v-on="listenersWithFileChange"
          />
        </a>

        <slot name="filenames">
          <div v-if="model" class="mt-2 text-sm italic">
            <p v-for="(filename, key) in filenames" :key="key">
              {{ filename }}
            </p>
          </div>
        </slot>
      </div>
    </label>

    <Error v-if="errorMessage" :message="errorMessage" />
  </div>
</template>

<script>
import { File } from '@/Components/Shared/Form/Core'
import Error from './Error.vue'
import Label from './Label.vue'

export default {
  components: {
    Error,
    Label,
  },
  mixins: [File],
}
</script>
