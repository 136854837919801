import { defineStore } from 'pinia'
import helper from '@/Utils/helper'
import axios from 'axios'
import { Inertia } from '@inertiajs/inertia'

let baseUrl = 'http://localhost:3333'
if (import.meta.env.PROD) {
  baseUrl = '/blog'
}
baseUrl = `${baseUrl}/wp-json/wp/v2/`

export const useReleaseNotesStore = defineStore('release-notes', {
  state: () => ({
    post: null,
  }),
  getters: {
    getPost: (state) => {
      return state.post
    },
    hasNewRelease: (state) => {
      return (userReleaseNotesReadAt) => {
        if (!userReleaseNotesReadAt) return true

        if (state.post) {
          return (
            new Date(userReleaseNotesReadAt) < new Date(state.post.date) &&
            new Date() > new Date(state.post.date)
          )
        }
        return false
      }
    },
  },
  actions: {
    async markAsRead() {
      await axios.post(route('release-notes.store'), {
        release_notes_read_at: helper.formatDate(
          new Date().toISOString(),
          'yyyy-MM-dd HH:mm:ss'
        ),
      })

      Inertia.reload({
        only: ['auth'],
      })
    },
    async fetchPosts() {
      try {
        const { data: category } = await axios.get(
          baseUrl + 'categories?slug=release-notes '
        )

        const { data } = await axios.get(
          baseUrl + `posts?categories=${category[0].id}&per_page=1&_embed`
        )

        const post = data && data[0]
        if (post) {
          this.post = post
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
})
