import dom from '@left4code/tw-starter/dist/js/dom'
import { Inertia } from '@inertiajs/inertia'
import { usePage } from '@inertiajs/inertia-vue3'
import useRoute from '@/Composables/useRoute'

const route = useRoute

// Setup side menu
const findActiveMenu = (subMenu) => {
  let match = false
  subMenu.forEach((item) => {
    if (
      (usePage().props.value.activeRoute !== undefined &&
        item.routeName?.split('.')[0] ===
          usePage().props.value.activeRoute.split('.')[0]) ||
      (usePage().props.value.activeRoute === undefined &&
        item.routeName === route.current())
    ) {
      match = true
    } else if (!match && item.subMenu) {
      match = findActiveMenu(item.subMenu)
    }
  })
  return match
}

const nestedMenu = (menu) => {
  menu.forEach((item, key) => {
    if (typeof item !== 'string') {
      let menuItem = menu[key]
      menuItem.active =
        (usePage().props.value.activeRoute !== undefined &&
          item.routeName?.split('.')[0] ===
            usePage().props.value.activeRoute.split('.')[0]) ||
        (usePage().props.value.activeRoute === undefined &&
          item.routeName?.split('.')[0] === route.current().split('.')[0]) ||
        (item.subMenu && findActiveMenu(item.subMenu))

      if (item.subMenu) {
        menuItem.activeDropdown = findActiveMenu(item.subMenu)
        menuItem = {
          ...item,
          ...nestedMenu(item.subMenu),
        }
      }
    }
  })

  return menu
}

const linkTo = (menu, event) => {
  if (menu.subMenu) {
    menu.activeDropdown = !menu.activeDropdown
  } else {
    event.preventDefault()
    Inertia.visit(menu.pageName)
  }
}

const enter = (el) => {
  dom(el).slideDown()
}

const leave = (el) => {
  dom(el).slideUp(300)
}

export { nestedMenu, findActiveMenu, linkTo, enter, leave }
