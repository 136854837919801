<script setup>
import { usePage } from '@inertiajs/inertia-vue3'
import { computed } from 'vue'

const user = computed(() => usePage().props.value.auth.user)

window.$crisp = []
window.CRISP_WEBSITE_ID = 'bc50cc8b-61a4-4234-a6dd-743466c29287'

if (user.value) {
  let nickName = user.value.name
  if (user.value.has_active_subscription) {
    nickName = `${user.value.name} (SUB)`
  }
  window.CRISP_TOKEN_ID = `${user.value.email}-${user.value.id}`
  window.$crisp.push(['set', 'user:nickname', nickName])
  window.$crisp.push(['set', 'user:email', user.value.email])
  window.$crisp.push([
    'set',
    'session:data',
    [
      [
        ['id', user.value.id],
        ['plan', user.value.plan_name],
        ['max_streams', user.value.max_streams],
        ['streams_running', user.value.streams_running],
        [
          'trial_period',
          user.value.trail_period === null
            ? 'Is subscriber'
            : user.value.trail_period,
        ],
      ],
    ],
  ])
}
;(function () {
  let d = document
  let s = d.createElement('script')
  s.src = 'https://client.crisp.chat/l.js'
  s.async = 1
  d.getElementsByTagName('head')[0].appendChild(s)
})()
</script>

<template></template>
