<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
    },
  },
}
</script>
