import { nextTick } from 'vue'
import Toastify from 'toastify-js'
import dom from '@left4code/tw-starter/dist/js/dom'
import { TOASTIFY } from '@/Utils/constants'

const useToastify = async (props, type = 'success') => {
  await nextTick()
  const node = dom(`#message-${type}`).clone().removeClass('hidden')

  if (props.text) {
    node.find('.js-text').html(props.text)
  } else {
    node.find('.js-text').html(TOASTIFY.success)
  }

  Toastify({
    node: node[0],
    duration: 5000,
    gravity: 'bottom',
    ...props,
  }).showToast()
}

export default useToastify
