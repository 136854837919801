<template>
  <nav aria-label="breadcrumb" class="mr-auto hidden xl:flex">
    <ol
      v-for="breadcrumb in breadcrumbs"
      :key="breadcrumb.title"
      class="breadcrumb"
    >
      <li
        v-if="!breadcrumb.is_current_page"
        class="breadcrumb-item transition hover:opacity-75 text-neutral-500"
      >
        <Link :href="breadcrumb.url" class="flex items-center"
          >{{ breadcrumb.title }}
          <Icon name="ChevronRight" class="w-3 h-3 mx-1"
        /></Link>
      </li>
      <li v-else class="dark:text-white">
        {{ breadcrumb.title }}
      </li>
      <span
        v-if="!breadcrumb.is_current_page"
        class="breadcrumb-item text-neutral-500"
      ></span>
    </ol>
  </nav>
</template>

<script setup>
import { usePage } from '@inertiajs/inertia-vue3'
import { computed } from 'vue'

const breadcrumbs = computed(() => usePage().props.value.breadcrumbs)
</script>
