<script setup>
import ApplicationLogo from '@/Components/Shared/ApplicationLogo.vue'
import CtaButton from '@/Components/Shared/Elements/CtaButton.vue'
import ThemeMode from '@/Components/Shared/Header/ThemeMode.vue'
import MainMenu from '@/Components/Shared/Navigation/MainMenu.vue'
import { Inertia } from '@inertiajs/inertia'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'

defineProps({
  isBuilder: {
    type: Boolean,
    default: false,
  },
})

const showMenu = ref(false)
const menuButton = ref()
const menu = ref()
onClickOutside(
  menu,
  () => {
    showMenu.value = false
  },
  { ignore: [menuButton] }
)

const toggleMenu = () => {
  showMenu.value = !showMenu.value
}

Inertia.on('start', () => {
  showMenu.value = false
})
</script>

<template>
  <CtaButton
    ref="menuButton"
    size="small"
    variant="rounded"
    border-style="faint"
    class="top-4 lg:-mt-0.5 right-4 lg:left-4 lg:right-auto fixed hover:!bg-neutral-900 hover:!text-white z-40 !p-2"
    :class="[
      { 'lg:bg-transparent cursor-auto lg:hidden': !isBuilder },
      { '!bg-neutral-900 !text-white': showMenu },
    ]"
    @click="toggleMenu"
  >
    <Icon name="Menu" class="w-6 h-6 transition-transform duration-300" />
  </CtaButton>
  <nav
    ref="menu"
    class="fixed flex-[0_0_300px] w-[300px] inset-0 lg:inset-auto bg-beige dark:bg-gradient-to-b dark:from-neutral-800 dark:to-neutral-900 border-r border-r-neutral-200 dark:border-r-neutral-700 flex flex-col -translate-x-full lg:translate-x-0 transition-transform duration-300 lg:transition-none ease-in-out z-30 lg:!top-0 lg:!h-full pt-20"
    :class="[
      {
        'lg:-translate-x-full lg:!fixed': isBuilder,
      },
      { '!translate-x-0 lg:!translate-x-0': showMenu },
    ]"
  >
    <ApplicationLogo
      class="pt-5 mb-5 absolute top-0"
      :no-icon="isBuilder"
      :class="{ 'lg:ml-14': isBuilder }"
    />
    <ThemeMode class="!top-5 !left-auto !right-3" />
    <MainMenu />
  </nav>
</template>
