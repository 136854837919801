<template>
  <div class="block">
    <label class="flex items-center" :class="wrapperClass">
      <input
        :name="name"
        :value="value"
        :checked="checked"
        type="radio"
        v-bind="{ ...$attrs, wrapperClass: undefined }"
        class="form-control"
        @change="emitChange"
        v-on="listenersWithoutChange"
      />

      <span class="ml-2 whitespace-nowrap"
        ><slot>{{ label }}</slot></span
      >
    </label>

    <Error v-if="errorMessage" :message="errorMessage" />
  </div>
</template>

<script>
import { Radio } from '@/Components/Shared/Form/Core'
import Error from './Error.vue'

export default {
  components: {
    Error,
  },
  mixins: [Radio],
}
</script>
