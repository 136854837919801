import { watch, onMounted } from 'vue'

const useWatchMounted = (watcher, cb, options) => {
  const getWatcherValues =
    typeof watcher === 'function' ? watcher : () => [watcher]

  onMounted(() => {
    const values = getWatcherValues()
    cb(Array.isArray(values) ? values : [values])
  })

  watch(watcher, cb, options)
}

export default useWatchMounted
