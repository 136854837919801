<script setup>
import { computed } from 'vue'
import Tooltip from '@/Components/Shared/Elements/Tooltip.vue'
import { twMerge } from 'tailwind-merge'

const props = defineProps({
  current: {
    type: Number,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  unit: {
    type: String,
    required: true,
  },
  tooltipMessage: {
    type: String,
    default: null,
  },
  tooltipPrefix: {
    type: String,
    default: '',
  },
  decimals: {
    type: Number,
    default: null,
  },
})

const percentage = computed(() =>
  Math.round((props.current / props.total) * 100)
)

const badgeColor = computed(() => {
  const percentValue = percentage.value
  if (percentValue >= 100) {
    return 'before:from-danger/60 before:to-danger/10 border-danger/20 dark:border-danger/30 before:border-r-0'
  }

  if (percentValue >= 90) {
    return 'before:from-danger/20 before:to-transparent before:border-danger/40'
  }

  if (percentValue >= 50) {
    return 'before:from-warning/20 before:to-transparent before:border-warning/40'
  }

  return 'bg-beige dark:bg-neutral-900'
})

const tooltipText = computed(
  () =>
    props.tooltipMessage || `${percentage.value}% ${props.tooltipPrefix} used`
)

const formatNumber = (number, decimals = props.decimals) => {
  return decimals !== null ? number.toFixed(decimals) : number
}
</script>

<template>
  <div
    class="mr-2 ml-2 lg:block hidden relative group/tooltip whitespace-nowrap"
    :style="{ '--percentage': `${percentage}%` }"
  >
    <span
      :class="
        twMerge(
          'flex items-center rounded-md text-xs font-semibold border border-black/10 dark:border-white/10 relative px-4 py-2 overflow-hidden z-20 before:border-r before:border-transparent before:from-white/10 before:to-transparent before:bg-gradient-to-l before:-z-10 before:absolute before:inset-0 before:[width:var(--percentage)]',
          badgeColor
        )
      "
      >{{ formatNumber(current) }} / {{ formatNumber(total, null) }}
      <small
        class="text-[10px] rounded-[4px] border border-black/40 dark:border-white/40 backdrop-blur-sm dark:bg-white/1 px-1 -mr-1 ml-2 uppercase"
        >{{ unit }}</small
      ></span
    >
    <Tooltip direction="top">{{ tooltipText }}</Tooltip>
  </div>
</template>
